import {useEffect, useRef, useState} from "react";
import axios from "axios";
import useLocale from "./useLocale";

const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }
  return array;
};

const useLoaderPhrases = (userCanInteract) => {
  const [currentPhrase, setCurrentPhrase] = useState("");
  const index = useRef(0);
  const duration = useRef(0);
  const locale = useLocale();
  const phrases = useRef([]);
  const intervalId = useRef();
  const botGroup = "FPT";

  useEffect(() => {
    const getPhrases = async () => {
      const res = await axios.post(
        process.env.REACT_APP_BASE_ENDPOINT + "/GetChatbotPills",
        {
          user: process.env.REACT_APP_CHATBOT_USER_ID,
          locale,
          botGroup: botGroup
        },
        {headers: {"Content-Type": "application/json"}}
      );
      phrases.current = shuffleArray(res.data.result?.messages || []);
      duration.current = res.data.result?.viewingDuration || 5;

      setCurrentPhrase(phrases.current[index.current]);
      index.current += 1;
    };
    getPhrases();

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    if (userCanInteract) {
      clearInterval(intervalId.current);
    } else {
      intervalId.current = setInterval(() => {
        setCurrentPhrase(
          phrases.current[index.current % phrases.current.length]
        );
        index.current += 1;
      }, duration.current * 1000);
    }
  }, [userCanInteract]);

  return currentPhrase;
};

export default useLoaderPhrases;
