import React from "react";
import "./App.css";
import ChatComponent from "./Component/ChatComponent/ChatComponent";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styleOptions: {
        emojiSet: true,
        backgroundColor: "var(--bg-gray)",
        hideUploadButton: true,
        avatarBorderRadius: "50%",
        avatarSize: 40,
        botAvatarBackgroundColor: "#E41E2D",
        botAvatarImage: "FPTIcon.svg",
        botAvatarInitials: "",
        showAvatarInGroup: "status",


        bubbleBackground: "#FFF",
        bubbleBorderColor: "#666666",
        bubbleBorderRadius: "0px 15px 15px 15px",
        bubbleBorderStyle: "solid",
        bubbleBorderWidth: 0,
        bubbleTextColor: "#666666",
        bubbleFromUserBackground: "#FFF",
        bubbleFromUserBorderColor: "#E41E2D",
        bubbleFromUserBorderRadius: "15px 15px 0px 15px",
        bubbleFromUserBorderWidth: 0,

        bubbleFromUserTextColor: "#666666",
        messageActivityWordBreak: "break-word",
        timestampColor:"#858585",
        notificationText: "#FFF",
        failedConnectivity: "#D3D3D3",
        slowConnectivity: "#D3D3D3",

        sendBoxHeight: 60,
        sendBoxButtonColor: "var(--iveco-blue)",
        sendBoxButtonColorOnDisabled: "#CCC",
        sendBoxButtonColorOnFocus: "var(--iveco-blue)",
        sendBoxButtonColorOnHover: "var(--iveco-blue)",
      },
      sectionOptions: {
        boxShadow: "0px 2px 5px rgba(0,0,0,.2)",
        minWidth: "500px",
        borderRadius: "15px",
        width: "40%",
        height: "30%",
        margin: "auto",
      },
    };
  }

  render() {
    const {
      state: {styleOptions, sectionOptions},
    } = this;

    return (
      <section className="chatWrapper">
        {/* <header>Website page</header> */}
        <div className="react-container">
          <ChatComponent
            styleOptions={styleOptions}
            sectionOptions={sectionOptions}
          />
        </div>
        <div className="disclaimer">
          <p>We inform you that the data you provide will be used exclusively to generate answers and improve the quality of the service. 
            In filling out the questions and using the service, we ask you to refrain from entering personal data referring to you or third parties
            that are not necessary to achieve the purposes being processed and/or confidential data. Your personal data necessary to use the service and the information entered,
            will be collected and stored only for the time necessary to enable the service to function and to protect Iveco Group from legal claims.
            For further details on the processing of your personal data, please refer to the <a href="https://www.ivecogroup.com/legal/privacy_policy" target="_blank">Privacy Notice</a> posted on ivecogroup.com.
            For additional information about personal data processing, you can write an email to <a href="mailto:privacy-compliance@ivecogroup.com">privacy-compliance@ivecogroup.com</a>.
            Please be aware that the answers provided may be inaccurate, as they are processed by artificial intelligence systems and are therefore not to be considered as official statements of <a href="https://www.ivecogroup.com" target="_blank">Iveco Group</a>.
          </p>
          <p>
            La informiamo che i dati da lei forniti saranno utilizzati esclusivamente per generare risposte e migliorare la qualità del servizio.
            Nella compilazione delle domande e nell'utilizzo del servizio, Le chiediamo di astenersi dall'inserire dati personali riferiti a Lei o a terzi
            che non siano necessari per il raggiungimento delle finalità oggetto del trattamento e/o dati aziendali riservati.
            I dati personali richiesti per l'utilizzo del servizio e le informazioni inserite saranno raccolti e conservati solo per il tempo necessario al funzionamento del servizio
            e per tutelare Iveco Group da eventuali azioni legali. Per ulteriori dettagli sul trattamento dei dati personali, La invitiamo a consultare l'<a href="https://www.ivecogroup.com/legal/privacy_policy" target="_blank">Informativa sulla Privacy</a> pubblicata
            sul sito ivecogroup.com. Per ulteriori informazioni sul trattamento dei dati personali, è possibile scrivere una e-mail a <a href="mailto:privacy-compliance@ivecogroup.com">privacy-compliance@ivecogroup.com</a>.
            Le risposte fornite potrebbero essere imprecise, in quanto elaborate da sistemi di intelligenza artificiale, e non sono pertanto da considerarsi dichiarazioni ufficiali di <a href="https://www.ivecogroup.com" target="_blank">Iveco Group</a>.
          </p>
        </div>
      </section>
    );
  }
}

export default App;
