import "./Loader.css";
import useCanInteractWithChat from "../utilities/useCanInteractWithChat";
import useLoaderPhrases from "../utilities/useLoaderPhrases";
import useLocale from "../utilities/useLocale";

const Loader = () => {
  const userCanInteract = useCanInteractWithChat();
  const loaderClassname = userCanInteract
    ? "loaderWrapper"
    : "loaderWrapper visible";
  let lang = useLocale();
  //Override Locale (english only for now)
  lang = "en-US";
  const titleText = 'FPT Industrial Facts';
  const loadingText =
    lang === "it-IT"
      ? "Sto cercando informazioni per rispondere alla tua domanda"
      : "I am looking for information to answer your question";
  

  const phrase = useLoaderPhrases(userCanInteract);
  return (
    <div className={loaderClassname}>
      <div class="container">
        <div className="loadingText">{loadingText}</div>

        <div className="phrase-container">
          <h2 className="h2-container">{titleText}</h2>
          {phrase}
        </div>
        <div className="loader-container">
          <span class="loader"></span>
        </div>
      </div>
    </div>
  );
};

export default Loader;
